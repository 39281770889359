import http from '../httpConfig';

const resetPassword = (token = '') => {
  const url = `/remember_password/${token}`;

  const validateToken = () => new Promise((resolve, reject) => {
    http.get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (
          typeof err === 'object'
          && 'response' in err
          && 'data' in err.response
        ) {
          reject(err.response.data.errors);
          return;
        }

        if (typeof err === 'string') {
          reject(err);
          return;
        }

        reject();
      });
  });

  const changePassword = (
    firstPassword = null,
    secondPassword = null,
  ) => new Promise((resolve, reject) => {
    const data = {
      'change_password': {
        plainPassword: {
          first: firstPassword,
          second: secondPassword,
        },
      },
    };

    http.put(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (
          typeof err === 'object'
          && 'response' in err
          && 'data' in err.response
        ) {
          reject(err.response.data.errors);
          return;
        }

        if (typeof err === 'string') {
          reject(err);
          return;
        }

        reject();
      });
  });

  return {
    validateToken,
    changePassword,
  };
};

export default resetPassword;
