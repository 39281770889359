<template>
  <article class="reset-password reset-password__box">
    <div>
      <h1>Redefinir senha</h1>

      <ui-form
        :ref="'form-reset-password'"
        class="reset-password__form"
        :form-data="formData"
        :sending="ui.isLoading"
        @valid="ui.isFormValid = $event"
      />
    </div>

    <ui-button
      :disabled="ui.isLoading || !ui.isFormValid"
      label="Redefinir senha"
      @click="submit"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <loader v-if="ui.isLoading" />
    </transition>
  </article>
</template>

<script>
import { mapActions } from 'vuex';
import resetPassword from '@/api/user/resetPassword';
import formData from '@/data/user/resetPassword';
import handleError from '@/mixins/handleError';
import Loader from '@/components/ui/Loader.vue';
import UiForm from '@/components/ui/form/_form.vue';
import UiButton from '@/components/ui/Button.vue';

export default {
  name: 'UserResetPassword',

  mixins: [handleError],

  components: {
    Loader,
    UiForm,
    UiButton,
  },

  data() {
    return {
      formData,
      resetPassword: null,
      ui: {
        isLoading: false,
        isFormValid: false,
      },
    };
  },

  computed: {
    token() {
      return this.$route.params.token || null;
    },
  },

  created() {
    this.resetPassword = resetPassword(this.token);
  },

  mounted() {
    const handleInvalidToken = () => {
      this.handleError(new Error('Token inválido.'));
      this.$router.push({ name: 'UserLogin' });
    };

    if (this.token) {
      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.resetPassword
        .validateToken()
        .catch((err) => {
          console.log(err);
          handleInvalidToken();
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });

      return;
    }

    handleInvalidToken();
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar', 'openModalDialog', 'closeModalDialog']),

    submit() {
      const firstPassword = this.formData.firstPassword.value;
      const secondPassword = this.formData.secondPassword.value;

      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.resetPassword
        .changePassword(firstPassword, secondPassword)
        .then(() => {
          this.openModalDialog({
            type: 'alert',
            title: 'Sucesso',
            text: 'Senha alterada com sucesso.',
            confirmText: 'Fechar',
            size: 'sm',
            fn: () => {
              this.closeModalDialog();
              this.$router.push({ name: 'UserLogin' });
            },
          });
        })
        .catch((error) => {
          const errorMessage = 'errors' in error
            ? this.findErrorMessage(error.errors)[0]
            : 'Ocorreu um erro ao redefinir sua senha.';

          this.handleError(errorMessage);
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });

      return false;
    },

    findErrorMessage(object) {
      let errorMessage = null;

      const recursiveSearch = (object) => {
        for (const value in object) {
          if (value === 'errors') {
            errorMessage = object[value];
            break;
          } else {
            recursiveSearch(object[value]);
          }
        }
      };

      if (Array.isArray(object)) {
        object.forEach((element) => {
          if (typeof element === 'object') {
            recursiveSearch(element);
          } else {
            errorMessage = element;
          }
        });
      } else {
        recursiveSearch(object);
      }

      return errorMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  max-width: 26rem !important;

  h1 {
    @apply text-secondary text-center;
  }

  &__box {
    @apply flex-col flex-wrap items-center justify-center h-full;
    @apply max-w-screen-md px-16 py-8 mt-0 mb-6 mx-auto;
    @apply bg-white;
    @apply shadow-xl;
    @apply rounded-4xl;
  }

  &__form {
    @apply mb-0 w-full block;
  }

  &__options {
    @apply flex justify-between items-center mb-6 text-primary;
    @apply text-xs;

    & > label {
      @apply flex items-center;
    }
  }
}
</style>
