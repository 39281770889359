const formData = {
  firstPassword: {
    name: 'firstPassword',
    type: 'password',
    label: 'Digite sua nova senha',
    value: null,
    required: true,
    lazu: true,
    disabled: false,
    minlength: 8,
    isValid: (password) => /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{8,}$/.test(password),
    errors: {
      minLength: '* Deve ter no mínimo 8 caracteres',
      isValid: '* Precisa conter números e letras',
    },
  },
  secondPassword: {
    name: 'secondPassword',
    type: 'password',
    label: 'Confirme sua nova senha',
    value: null,
    required: true,
    lazy: true,
    disabled: false,
    minlength: 8,
    sameAs: 'firstPassword',
  },
};

export default formData;
